import Link from "./bases/link"
import PropTypes from "prop-types"
import React from "react"
import { Heading, Header, Box } from "grommet"

const HeaderComponent = ({ siteTitle, ...rest }) => (
  <Header
    direction="row"
    align="center"
    justify="center"
    pad={{ left: "large", right: "large", vertical: "large" }}
    style={{ zIndex: "1" }}
    {...rest}
  >
    <Box justify="between" width={{ max: "large" }} direction="row" fill>
      <Heading level="3" margin="none">
        <Link to="/">{siteTitle}</Link>
      </Heading>
    </Box>
  </Header>
)

HeaderComponent.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderComponent.defaultProps = {
  siteTitle: ``,
}

export default HeaderComponent
